// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../../../node_modules/postcss-loader/dist/cjs.js!../Dropdown/dropdown.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pagination-root-SR6 {\n    max-width: 800px;\n}\n\n.pagination-dropdownWrapper-55h {\n    /* composes: mt-8 md_mt-0 from global; */\n}\n\n.pagination-arrowWrapper-hgP {\n}\n\n.pagination-dropdownWidth-UhA {\n    cursor: pointer;\n    width: 89px;\n}\n\n.pagination-dropdownOptions-mlA {\n    max-height: 200px;\n    overflow-y: auto !important;\n}\n\n.pagination-dropdownWidthPageSize-PkD {\n    min-width: 8rem;\n    width: auto;\n    position: relative;\n}\n.pagination-dropdownWidthPageSize-PkD::after {\n    right: -2rem;\n    content: '';\n    background-color: var(--venia-global-color-urbane-bronze);\n    width: 1px;\n    height: 100%;\n    position: absolute;\n    top: 0;\n}\n\n.pagination-paginationWrap-zsw {\n    /* display: grid;\n    grid-template-columns: repeat(2, minmax(0, 1fr)); */\n}\n\n@media screen and (max-width: 1280px) {\n    .pagination-dropdownWidthPageSize-PkD::after {\n        right: -1rem;\n    }\n\n    /* .paginationWrap {\n        grid-template-columns: repeat(1, minmax(0, 1fr));\n        gap: 1.5rem\n    } */\n}\n\n@media screen and (max-width: 768px) {\n    .pagination-root-SR6 {\n        margin-top: 2rem;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "pagination-root-SR6 flex flex-col justify-center relative flex-1",
	"dropdownWrapper": "pagination-dropdownWrapper-55h md_absolute md_top-0 md_right-0 flex justify-center items-center text-bronze",
	"arrowWrapper": "pagination-arrowWrapper-hgP flex justify-center xl_mr-[4rem] flex-1 mb-12 md_mb-0",
	"dropdownWidth": "pagination-dropdownWidth-UhA ml-4 xl_ml-8",
	"dropdownOptions": "pagination-dropdownOptions-mlA " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["dropdownOptions"] + "",
	"dropdownWidthPageSize": "pagination-dropdownWidthPageSize-PkD pagination-dropdownWidth-UhA ml-4 xl_ml-8 mr-4 xl_mr-8",
	"paginationWrap": "pagination-paginationWrap-zsw"
};
export default ___CSS_LOADER_EXPORT___;
