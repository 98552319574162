import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React, { useEffect, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { shape, string } from 'prop-types';
import { useStyle } from '@magento/venia-ui/lib/classify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Form } from 'informed';
import Field from '@magento/venia-ui/lib/components/Field';
import TextInput from '@magento/venia-ui/lib/components/TextInput';
import { isRequired } from '@magento/venia-ui/lib/util/formValidators';
import defaultClasses from './footer.module.css';
import FooterIconLinks from './footerIconLinks';
import Newsletter from '../Newsletter';
import { useFooterData } from './useFooterData';
import Button from '../Common/Button/button';
import FooterLinksGroup from './footerLinksGroup';
import { useSocialIcons } from './useSocialIcons';
import { useUserContext } from '@magento/peregrine/lib/context/user';

var Footer = function Footer(_ref) {
  var _footerBottomData$lin, _footerBottomData$soc;

  var classNames = _ref.classes;

  var _useState = useState({
    copyrightText: '',
    links: [],
    socialIcons: []
  }),
      _useState2 = _slicedToArray(_useState, 2),
      footerBottomData = _useState2[0],
      setFooterBottomData = _useState2[1];

  var socialIcons = useSocialIcons();
  var history = useHistory();

  var _useLocation = useLocation(),
      pathname = _useLocation.pathname;

  var _useUserContext = useUserContext(),
      _useUserContext2 = _slicedToArray(_useUserContext, 1),
      isSignedIn = _useUserContext2[0].isSignedIn;

  var classes = useStyle(defaultClasses, classNames);

  var _useFooterData = useFooterData(),
      isLoading = _useFooterData.isLoading,
      error = _useFooterData.error,
      footerData = _useFooterData.footerData,
      copyrightData = _useFooterData.copyrightData;

  var sortedFooterLinks = useMemo(function () {
    var _copyrightData$find;

    if (!copyrightData) return [];

    var socialIconRow = _toConsumableArray(copyrightData === null || copyrightData === void 0 ? void 0 : (_copyrightData$find = copyrightData.find(function (data) {
      return data.name === '3';
    })) === null || _copyrightData$find === void 0 ? void 0 : _copyrightData$find.row);

    return _toConsumableArray(socialIconRow === null || socialIconRow === void 0 ? void 0 : socialIconRow.sort(function (a, b) {
      return (a === null || a === void 0 ? void 0 : a.sortorder) - (b === null || b === void 0 ? void 0 : b.sortorder);
    }));
  }, [copyrightData]);
  useEffect(function () {
    copyrightData === null || copyrightData === void 0 ? void 0 : copyrightData.map(function (item) {
      if (item.name === '1') {
        setFooterBottomData(function (prevState) {
          return _objectSpread(_objectSpread({}, prevState), {}, {
            copyrightText: item.row
          });
        });
      } else if (item.name === '2') {
        setFooterBottomData(function (prevState) {
          return _objectSpread(_objectSpread({}, prevState), {}, {
            links: item.row
          });
        });
      } else {
        setFooterBottomData(function (prevState) {
          return _objectSpread(_objectSpread({}, prevState), {}, {
            socialIcons: sortedFooterLinks
          });
        });
      }
    });
  }, [copyrightData, sortedFooterLinks]);

  var handleSubmitTracker = function handleSubmitTracker(values) {
    var searchQuery = Object.keys(values).map(function (urlKey) {
      return "".concat(encodeURIComponent(urlKey), "=").concat(encodeURIComponent(values[urlKey]));
    }).join('&');
    history.push({
      pathname: 'po-tracker',
      search: searchQuery
    });
  };

  var footerBootomLinks = React.createElement("ul", {
    className: "flex flex-col xl_flex-row"
  }, footerBottomData === null || footerBottomData === void 0 ? void 0 : (_footerBottomData$lin = footerBottomData.links) === null || _footerBottomData$lin === void 0 ? void 0 : _footerBottomData$lin.map(function (item, key) {
    return React.createElement("li", {
      key: key,
      className: "mx-3 mb-4 xl_mb-0 text-center whitespace-nowrap"
    }, React.createElement(Link, {
      to: item.url,
      "aria-label": item.Name
    }, item.Name));
  }));
  var socialIconContent = React.createElement("ul", {
    className: "flex justify-center xl_justify-end mb-8 mt-4 xl_my-0"
  }, footerBottomData === null || footerBottomData === void 0 ? void 0 : (_footerBottomData$soc = footerBottomData.socialIcons) === null || _footerBottomData$soc === void 0 ? void 0 : _footerBottomData$soc.map(function (item, key) {
    return React.createElement("li", {
      key: key,
      className: "".concat(item.icon === 'houzz' && classes.houzzIcon, " mx-1.5 flex items-center")
    }, React.createElement("a", {
      target: "_blank",
      href: item.url,
      "aria-label": item.Name,
      rel: "noopener"
    }, socialIcons[item.icon], ' ', React.createElement("span", {
      className: "sr-only"
    }, "".concat(item.Name, " link"))));
  }));
  return React.createElement("footer", {
    className: "border-t border-solid border-black"
  }, React.createElement("div", {
    className: "".concat(classes.grayFooterItemsContainer, " bg-gray")
  }, React.createElement("div", {
    className: "container flex items-center justify-around h-full"
  }, React.createElement(FooterIconLinks, null))), React.createElement("div", {
    className: "bg-white text-black pt-4 sm_pt-20 pb-4 sm_pb-24 sm_border-b mb-5 sm_mb-0 sm_border-solid sm_border-gray"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "".concat(classes.footerContent, " sm_gap-x-8  xl_gap-x-0")
  }, React.createElement("div", {
    className: "".concat(classes.footerLinks, "  footer-links-wrapper sm_border-b sm_border-solid sm_border-gray xl_border-none pb-0 sm_pb-9 mb-0 sm_mb-9 sm_gap-x-4 md_gap-x-2 lg_col-span-10 2xl_col-span-10")
  }, React.createElement(FooterLinksGroup, {
    footerData: footerData
  })), React.createElement("div", {
    className: classes.newsletterWrapper
  }, React.createElement(Newsletter, null)), pathname !== '/po-tracker' ? React.createElement("div", {
    className: classes.trackerWrapper
  }, React.createElement(FormattedMessage, {
    id: 'HF EMPLOYEE CENTRAL',
    defaultMessage: 'PO TRACKER'
  }), React.createElement(Form, {
    onSubmit: handleSubmitTracker
  }, !isSignedIn && React.createElement("div", {
    className: classes.ffield
  }, React.createElement(Field, {
    id: "account_number",
    label: "Account Number"
  }, React.createElement(TextInput, {
    field: "account_number",
    id: "account_number",
    placeholder: "Account Number *",
    validate: isRequired,
    validateOnBlur: true
  }))), React.createElement("div", {
    className: isSignedIn ? classes.ffield : classes.poNumber
  }, React.createElement(Field, {
    id: "po_number",
    label: "Order PO Number"
  }, React.createElement(TextInput, {
    field: "po_number",
    id: "po_number",
    placeholder: "Order PO Number *",
    validate: isRequired,
    validateOnBlur: true
  }))), React.createElement("div", {
    className: classes.ffield
  }, React.createElement(Button, {
    type: "submit",
    priority: "normal",
    "aria-label": "Submit Track Order"
  }, "Submit")))) : null, React.createElement("div", {
    className: pathname !== '/po-tracker' ? classes.loginWrapper : "".concat(classes.noPoTrackerLogin, " ").concat(classes.loginWrapper)
  }, React.createElement(FormattedMessage, {
    id: 'HF EMPLOYEE CENTRAL',
    defaultMessage: 'HF EMPLOYEE CENTRAL'
  }), React.createElement("a", {
    href: "http://ec.hubbardtonforge.com/",
    target: "_blank",
    rel: "noopener"
  }, React.createElement(Button, {
    size: "small",
    priority: "low",
    classes: {
      root_lowPriority: defaultClasses.loginFooterButton
    },
    "aria-label": "Hubbardton Forge Employee Central login"
  }, React.createElement(FormattedMessage, {
    id: 'LOGIN',
    defaultMessage: 'LOGIN'
  })))), React.createElement("div", {
    className: classes.mobileDivider
  })))), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "flex flex-col xl_flex-row xl_justify-between items-center pt-3 xl_pt-7 xl_mb-5 text-bronze border-t border-solid border-border-gray sm_border-0"
  }, React.createElement("p", {
    className: "".concat(classes.footerBottomItem, " text-center xl_text-left order-3 xl_order-1 pt-4 xl_pt-0 mb-3 xl_mb-0 border-t border-solid border-border-gray xl_border-none text-sm")
  }, "Copyright \xA9 ", new Date().getFullYear(), ". All Rights Reserved."), React.createElement("div", {
    className: "".concat(classes.footerBottomItem, " xl_flex order-1 xl_order-2 items-center text-sm")
  }, footerBootomLinks, React.createElement("div", {
    className: "mb-4 xl_mb-0 xl_ml-3"
  }, React.createElement("a", {
    href: "https://mrf.healthcarebluebook.com/luminarehealth",
    target: "_blank",
    rel: "noopener"
  }, "Transparency in Coverage Rule"))), React.createElement("div", {
    className: "".concat(classes.footerBottomItem, " order-2 xl_order-3")
  }, socialIconContent))));
};

export default Footer;
Footer.propTypes = {
  classes: shape({
    root: string
  })
};